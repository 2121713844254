.adduser_main_wrapper {
  margin-top: 14px;
  padding: 1rem 5.187rem 1.5rem 5.187rem;
  background: #f4f5f7;
}
.form_wrapper_adduser {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 30px 30px;

  color: #000;
  overflow-x: auto;
}
.header_from_user {
  padding: 10px 25px;
  color: #000;
  background: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.form_basicInfo {
  padding: 0px 10px;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Manrope";
}

.bunddle_section {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.bunddle_section .btn_wrapper {
  display: flex;
  align-items: center;
  gap: 10;
  padding-right: 10px;
}
.bunddle_section .btn_wrapper button {
  width: 50px;
  border-radius: 100px;
  gap: 10px;
}
span {
  padding: 0 10px;
}

hr {
  color: var(--bs-border-width) solid var(--bs-border-color);
  padding: 10px;
}
.form_div_add_user {
  padding: 10px 20px;
}
.mainDivSection {
  display: flex;
  flex-direction: column;
  row-gap: 35px;
}
@media (max-width: 1250px) {
  .mainDivSection {
    row-gap: 75px;
  }
}

.update_user_form {
  padding: 10px 20px;
}
.input_fields_wrapper_adduser {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
} */
.view_order_heading {
  font-size: 1.125rem;
  font-weight: bolder !important;
}

.details_order {
  color: #1f1f1f;
  font-size: 14px;
  margin-bottom: 0.5rem;
  line-height: 1.3rem;
}

.table-responsive {
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.input_fields_labels_adduser {
  display: flex;
  flex-direction: column;
  width: 48%;
}
label {
  font-size: 14px;
  margin-top: 7px;
}

td svg {
  cursor: pointer;
}

.input_fields_labels_adduser input {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
}
.input_2 {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
  outline: none;
}

textarea {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
  outline: none;
}
input::placeholder {
  padding-left: 10px;
}
select {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
  /* font-family: Monstrate; */

  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
  outline: none;
}
select::placeholder {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
}

.add_userBtn {
  margin: 25px 15px;
  background: #000;
}
.text_area {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
}
.save_btn {
  margin: 15px 15px;
  background: #000;
  padding: 10px 40px;
  width: 0;
}

.input_text_editor {
  height: 200px !important;
}

.errorinput_3 {
  border: 1px solid red !important;
  height: 200px !important;
}

.errorinput_3::placeholder {
  color: red;
  font-size: 12px;
  font-weight: 200 !important;
  line-height: normal;
}

.errorinput_2 {
  border: 1px solid red !important;
  outline: none;

  /* border: 1px solid #e3e3e3; */
  border-radius: 6px;
  padding: 8px;
}
.errorinput_2::placeholder {
  color: red;
  font-size: 10px;
  font-weight: 300;
}

.logo_div_inOrder {
  display: flex;
  margin-top: 10px;
}
.hder_time {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.hder_time_order_id {
  font-size: 23px;
  font-weight: 500;
  line-height: 1;
}
.invc_to {
  font-size: 20px !important;
  font-weight: bolder !important;
  line-height: 1 !important;
  margin-top: 40px !important;
}
.name_in_pdf {
  font-size: 16px;
  font-weight: bolder !important;
  margin-top: 40px;
}
.addres_in_pdf {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.css-1p3m7a8-multiValue {
  background-color: #000 !important;
  color: white !important;
  border-radius: 10px !important;
}
.css-wsp0cs-MultiValueGeneric {
  color: white !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.badge-opacity-primary {
  background: #ffd7bb;
  color: #fc6902;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
}
.gallery_images {
  position: relative;
}
.gallery_remove_icon {
  position: absolute;
  border: 1px #ccc solid;
  right: 3px;
  background: #fefefe;
}
